@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

body {
 background: #f1f5f9;
 font-family: 'Roboto', sans-serif;
}


section{
  width: 80%;
  margin: 0 auto;
  word-break: unset;
}

article{
  margin: 1rem 0;
  text-align: justify;
  font-size: 1rem;
}

article p {
  margin: 0 2rem;
  margin-bottom: 0.5rem;
}

ul{
  list-style: circle;
}
li{
  margin-left: 5rem;
}
a {
  font-weight: 700;
  text-decoration: underline;
}

.h1-tittle{
  font-family: 'Comfortaa', sans-serif;
  font-weight: bold;
  color: #c00750;
}

.h3-tittle{
  font-family: 'Comfortaa', sans-serif;
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
}

h4{
  font-family: 'Comfortaa';
  font-weight: 700;
  border-bottom: 1px solid;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
}

h5{
  font-family: 'Comfortaa';
  font-weight: bold;
}


/* PERSONNAL STYLESHEET */
.bg-orange{
  background-color: #f5b722;
}

.text-rose{
  color: #c00750;
}

.border-orange{
  border-color: #f5b722;
}

.text-rose{
  color: #c00750;
}

.cookie{
  font-weight: 700;
}

article span{
  background-color: rgb(203 213 225);
  padding: 0.15rem 0.5rem;
  margin: 0.15rem 0.25rem;
  border-radius: 0.5rem;
}

.large-input{
  width: 50%;
}

.copy-paste{
  display: flex;
  right: 14rem;
  top: 38rem;
  cursor: pointer;
  padding: 1rem 2rem;
}